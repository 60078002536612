import { Icon } from '/features/buildingBlocks/Icon'
import { animated, useSpring, useTransition } from 'react-spring'
import iconChevronRight from '/images/icons/chevron-right.raw.svg'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'
import iconArrowLeft from '/images/icons/arrow-left.raw.svg'
import styles from './Button.css'

export function ButtonPrimary({ children, dataX, onClick = undefined, href = undefined, type = undefined, layoutClassName = undefined, download = undefined,  ariaLabel = undefined, ariaExpanded = undefined, target = undefined  }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return <Base className={cx(styles.componentPrimary, layoutClassName)} {...{ children, dataX, onClick, href, download, ariaLabel, ariaExpanded, type, target }} />
}

export function ButtonSecondary({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, download = undefined,  ariaLabel = undefined, ariaExpanded = undefined, target = undefined  }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return <Base className={cx(styles.componentSecondary, layoutClassName)} {...{ children, dataX, onClick, href, download, ariaLabel, ariaExpanded, target }} />
}

export function ButtonBlack({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, download = undefined,  ariaLabel = undefined, ariaExpanded = undefined, target = undefined  }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return <Base className={cx(styles.componentBlack, layoutClassName)} {...{ children, dataX, onClick, href, download, ariaLabel, ariaExpanded, target }} />
}

export function ButtonWhite({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, download = undefined,  ariaLabel = undefined, ariaExpanded = undefined, target = undefined  }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return <Base className={cx(styles.componentWhite, layoutClassName)} {...{ children, dataX, onClick, href, download, ariaLabel, ariaExpanded, target }} />
}

export function ButtonFatChevronRightPrimary({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, download = undefined, target = undefined  }) {
  const Base = href ? ABase : ButtonBase
  return (
    <Base className={cx(styles.componentFatChevronRightPrimary, layoutClassName)} {...{ dataX, onClick, href, download, target }}>
      <span>{children}</span>
      <Icon icon={iconChevronRight} />
    </Base>
  )
}

export function ButtonArrowRight({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, target = undefined }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return (
    <Base className={cx(styles.componentArrowRight, layoutClassName)} ariaLabel={children} {...{ dataX, onClick, href, target }} >
      <Icon icon={iconArrowRight} />
    </Base>
  )
}

export function ButtonArrowLeft({ children, dataX, onClick = undefined, href = undefined, type = 'button', layoutClassName = undefined, target = undefined }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return (
    <Base className={cx(styles.componentArrowLeft, layoutClassName)} ariaLabel={children} {...{ type, dataX, onClick, href, target  }} >
      <Icon icon={iconArrowLeft} />
    </Base>
  )
}

export function ButtonAnimated({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, target = undefined, colorContext = undefined }) {
  const Base = href ? AnimatedABase : AnimatedButtonBase
  return (
    <Base className={cx(styles.componentAnimated, layoutClassName)} ariaLabel={children} {...{ dataX, onClick, href, target, colorContext }} >
      {children}
    </Base>
  )
}


function ABase({ dataX, href, children, className, onClick, download = undefined, target }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined
  return (
    <a className={cx(styles.componentABase, className)} {...{ href, onClick, rel, target, download }} data-x={dataX}>
      <span className={styles.baseInner}>{children}</span>
    </a>
  )
}

function ButtonBase({ className, dataX, children, onClick, ariaLabel = undefined, ariaExpanded = undefined }) {
  return (
    <button className={cx(styles.componentBase, className)} data-x={dataX} aria-label={ariaLabel} aria-expanded={ariaExpanded} {...{ onClick }} >
      <span className={styles.baseInner}>{children}</span>
    </button>
  )
}

function AnimatedButtonBase({ className, dataX, children, onClick, ariaLabel = undefined, ariaExpanded = undefined, colorContext = undefined }) {
  const [hover, setHover] = React.useState(false)
  const slideTransition = useTransition(hover, {
    from: { x: '-100%' },
    enter: { x: '0%' },
    leave: { x: '100%' }
  })

  const buttonBorder = useSpring({
    from: {
      borderRadius: '40px',
      scale: 1
    },
    to: {
      borderRadius: hover ? '20px' : '40px',
      scale: hover ? 1.1 : 1
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 20
    }
  })

  return (
    <animated.button style={buttonBorder} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={cx(styles.componentAnimatedButtonBase, className)} data-x={dataX} aria-label={ariaLabel} aria-expanded={ariaExpanded} {...{ onClick }} >
      { slideTransition((style, button) =>
        button ? <animated.div {...{ style }} className={styles.backgroundAnimation} /> : null
      )}
      <span className={styles.inner}>{children}</span>
    </animated.button>
  )
}

function AnimatedABase({ dataX, href, children, className, onClick, download = undefined, target, colorContext = undefined }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined
  const [hover, setHover] = React.useState(false)
  const slideTransition = useTransition(hover, {
    from: { x: '-100%' },
    enter: { x: '0%' },
    leave: { x: '100%' }
  })

  const buttonBorder = useSpring({
    from: {
      borderRadius: '40px',
      scale: 1
    },
    to: {
      borderRadius: hover ? '20px' : '40px',
      scale: hover ? 1.05 : 1
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 20
    }
  })

  return (
    <animated.a
      data-x={dataX}
      style={buttonBorder}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cx(styles.componentAnimatedABase, className)}
      {...{ href, onClick, rel, target, download }}
    >
      <span className={styles.inner}>{children}</span>
      {slideTransition((style, button) =>
        button ? <animated.div {...{ style }} className={styles.backgroundAnimation} /> : null
      )}
    </animated.a>
  )
}
